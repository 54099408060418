export const environment = {
  production: false,
  name: "dev",
  defaultLocale: "en",
  defaultCurrency: 2,
  appRoot: "https://userdev.mydataknox.com",
  apiEndpoint: "https://apidev.mydataknox.com",
  wpEndpoint: "https://wpdev.mydataknox.com/wp-json",
  vncEndpoint: "https://vncdev.mydataknox.com/novnc",
  recaptchaKey: "6Lfamg8cAAAAAMjYy3Vf46TJWrkIArF_KziJXcf3",
  wpLink: "https://wpdev.mydataknox.com/en",
  faqLink: "https://wpdev.mydataknox.com/en/knowledge-base",
  affiliateFaqLink: "https://wpdev.mydataknox.com/en/knowledge-base",
  freeDomainLink:
    "https://wpdev.mydataknox.com/en/knowledge-base/domains/domain-registration/my-first-domain-is-free-how-where-what-do-i-need-to-do",
  tos: "https://wpdev.mydataknox.com/en/terms-of-use",
  contact: "https://wpdev.mydataknox.com/en/support/contact-us",
  monriurl: "https://ipgtest.monri.com/dist/components.js",
  productSets: {
    domain: ["domain"],
    whost: [
      "hosting_standard",
      "hosting_advanced",
      "hosting_reseller",
      "hosting_radio",
    ],
    vps: ["vps_standard"],
    dserver: ["server_mdk_l", "server_mdk_xl"],
    ssl: ["ssl"],
    backup: ["backup_r1", "backup_veeam"],
    licence: ["licence_cpanel", "licence_various", "email_office", "support"],
  },
  productSetsCartView: {
    cartSet1_servers: [
      "server_mdk_l",
      "server_mdk_xl",
      "vps_standard",
      "licence_cpanel",
      "licence_various",
      "backup_r1",
      "backup_veeam",
    ],
    cartSet2_whost: [
      "hosting_standard",
      "hosting_advanced",
      "hosting_reseller",
      "hosting_radio",
      "domain",
      "ssl",
    ],
    cartSet3_cloud: ["backup_r1", "backup_veeam"],
    cartSet4_office: ["email_office", "support"],
  },
  languageList: [
    { code: "hr", label: "Hrvatski" },
    { code: "en", label: "English" },
  ],
  paypalClientId:
    "AbW_-QwSfPFEtGPh-0tCIW7-9PP7FOCfR1QsT2ccm16gY0UWTc_CbIxpchuawSkMRDKhSWy79pCWEOyX",
  openReplay: {
    start: false,
    projectKey: "2YKkATZANc36llMQq6bp", // TODO: Change this to development project key
    ingestPoint: "https://stats.mydataknox.com/ingest", // TODO: Change this to development ingest point
    __DISABLE_SECURE_MODE: true,
    obscureTextNumbers: false,
    obscureTextEmails: false,
    obscureInputEmails: false, // Obscure emails in input fields. Email values will be converted to a random chain of asterisks. Default: true.
    obscureInputDates: false, // Obscure dates in input fields. Date values will be converted to a random chain of asterisks. Default: false.
    defaultInputMode: 0, //0 | 1 | 2 Default capture mode for input values. Respectively: plain, obscured or ignored. Default: 1 (obscured).
  },
  displayHrk: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
