import { Component, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Currency } from "../../_models/currency";
import { CartOrderItem } from "../cart-order-item";
import { CartService } from "../cart.service";
import { ProductDomainItem } from "../product-domain-item";

@Component({
  selector: "app-cart-items",
  templateUrl: "./cart-items.component.html",
})
export class CartItemsComponent implements OnDestroy {
  @Input() cartCurrency: Currency;
  private subscription: Subscription = new Subscription();
  faTrash = faTrash;

  orderItems: CartOrderItem[] = [];
  domainItems: ProductDomainItem[] = [];
  total: number;

  constructor(private cartService: CartService) {
    this.subscription.add(
      this.cartService.orderItems$.subscribe((orderItems) => {
        this.orderItems = orderItems === null ? [] : orderItems;
        this.calculateTotal();
      })
    );
    this.subscription.add(
      this.cartService.domainItems$.subscribe((domainItems) => {
        this.domainItems = domainItems === null ? [] : domainItems;
        this.calculateTotal();
      })
    );
  }

  calculateTotal() {
    this.total = 0;
    if (this.domainItems.length > 0) {
      for (const domainItem of this.domainItems) {
        this.total += domainItem.price;
      }
    }
    if (this.orderItems.length > 0) {
      for (const orderItem of this.orderItems) {
        if (orderItem.unitPriceDiscount) {
          orderItem.totalPriceDiscount =
            orderItem.unitPriceDiscount * orderItem.quantity;
          this.total += orderItem.totalPriceDiscount;
        } else {
          orderItem.totalPrice = orderItem.unitPrice * orderItem.quantity;
          this.total += orderItem.totalPrice;
        }
      }
    }
  }

  /**
   * Removes item from list.
   */
  removeItem(orderItem: CartOrderItem): void {
    this.cartService.removeOrderItem(orderItem);
  }

  removeDomain(domain: ProductDomainItem) {
    this.cartService.removeDomain(domain);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
