import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {first} from 'rxjs/operators';
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons';

import {AlertService, AuthenticationService, ClientService} from '../../_services';
import {MustMatch} from '../../_helpers/form-custom-validators';
import {SystemService} from '../../_services/system.service';

@Component({
  templateUrl: 'profile-password.component.html'
})
export class ProfilePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  loading = false;
  submitted = false;
  currentShown = false;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  // At least one lowercase, one uppercase, one special character, and 8-64 valid characters
  public passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\$%@?+\-`!;,.:#*()_\[\]\{\}])[\w\$%@?+\-`!;,.:#*()_\[\]\{\}]{8,64}$/;
  shown = {
    password: false,
    confirmPassword: false,
    currentPassword: false
  };

  constructor(
    private formBuilder: FormBuilder,
    private userService: ClientService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthenticationService,
    private systemService: SystemService
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(64), Validators.pattern(this.passPattern)]],
      confirmPassword: [null, [Validators.required]],
      currentPassword: [null, [Validators.required]]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.changePasswordForm.controls; }

  viewInput(target: string) {
    if (target === 'password') {
      this.shown.password = !this.shown.password;
    } else if (target === 'confirmPassword') {
      this.shown.confirmPassword = !this.shown.confirmPassword;
    } else if (target === 'currentPassword') {
      this.shown.currentPassword = !this.shown.currentPassword;
    } else {
      this.shown.password = !this.shown.password;
      this.shown.confirmPassword = !this.shown.confirmPassword;
    }
  }

  generateRootPassword() {
    const newPass = this.systemService.genRandomPattern(this.passPattern.source);
    this.shown.password = true;
    this.changePasswordForm.patchValue({password: newPass});
    this.changePasswordForm.patchValue({confirmPassword: newPass});
  }

  viewCurrentInput() {
    this.currentShown = !this.currentShown;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.passReset(this.changePasswordForm.value)
      .pipe(first())
      .subscribe(res => {
          this.loading = false;
          if (res && res.body.status !== undefined) {
            this.alertService.success(res.body.message, true);
            this.authService.logout(true);
            this.router.navigate(['/login']);
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }
}
