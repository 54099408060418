import {FormGroup} from '@angular/forms';

/**
 * custom validator to check that two fields match
 * @param controlName string
 * @param matchingControlName string
 */
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({mustMatch: true});
    } else {
      matchingControl.setErrors(null);
    }
  };
}

/**
 * Sets dependent control to required if first control has values
 * @param controlName string
 * @param dependentControlName string
 */
export function MutualDependence(controlName: string, dependentControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const dependentControl = formGroup.controls[dependentControlName];

    if (control.value === undefined || control.value === null || control.value === '') {
      // Clear the dependentControl's errors if the main control's value is empty
      dependentControl.setErrors(null);
      return;
    }

    // Set error on dependentControl if validation fails
    if (dependentControl.value === undefined || dependentControl.value === null || dependentControl.value === '') {
      dependentControl.setErrors({dependent: true});
    } else {
      dependentControl.setErrors(null);
    }
  };
}
