import {Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {of, Subscription, zip} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {Options} from '@m0t0r/ngx-slider';

import {CartService} from '../../cart.service';
import {Currency} from '../../../_models/currency';
import {ProductService} from '../../../_services/product.service';
import {ProductGroup} from '../../../_models/product-group';
import {Product} from '../../../_models/product';
import {CustomValidator} from '../../../_components';
import {ProductConfigOption} from '../../../_models/product-config-option';
import {CartOrderItem} from '../../cart-order-item';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';

@Component({
  selector: 'app-product-backup',
  templateUrl: './product-backup.component.html'
})
export class ProductBackupComponent implements OnInit, OnDestroy {
  @Input() productGroups: ProductGroup[];
  @Input() cartCurrency: Currency;
  @Input() configOptions: ProductConfigOption[] = [];
  private subscription: Subscription = new Subscription();
  backupForm: FormGroup;
  backupProducts: Product[];
  submitted = false;
  loading = {
    product: false
  };
  productId: number;
  product: Product;
  returnToOptionCid: number;
  public displayHrk = false;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    @Inject(APP_CONFIG) private config: AppConfig,
    private cartService: CartService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  get f() {
    return this.backupForm.controls;
  }

  get c() {
    return this.f.config as FormArray;
  }

  get cu() {
    return this.f.customfields as FormArray;
  }

  ngOnInit() {
    this.displayHrk = this.config.displayHrk;

    if (!this.productGroups) {
      this.subscription.add(
        this.productService.getProductGroups()
          .pipe(take(1))
          .subscribe(() => {
            this.productGroups = this.productService.getProductGroupsBySet('backup');
          })
      );
    }
    if (!this.cartCurrency) {
      this.subscription.add(
        this.cartService.getCartCurrency()
          .subscribe(currency => {
            this.cartCurrency = currency;
          })
      );
    }

    const routerQidSub = this.route.queryParams
      .pipe(switchMap(params => of(params)))
      .subscribe(params => {
        if (this.backupForm) {
          this.c.clear();
          this.cu.clear();
        }
        this.productId = (typeof params.pid !== 'undefined') ? parseInt(params.pid, 10) : null;
        const period = (typeof params.period !== 'undefined') ? parseInt(params.period, 10) : null;
        const cartId = (typeof params.cid !== 'undefined') ? parseInt(params.cid, 10) : null;
        this.returnToOptionCid = (typeof params.returnOption !== 'undefined') ? parseInt(params.returnOption, 10) : null;

        // If product id is set, get product by id
        if (this.productId) {
          this.loading.product = true;
          const subSingle = this.productService.getProductById(this.productId, this.cartCurrency.code)
            .subscribe(product => {
              this.loading.product = false;
              this.product = product;
              const pricingPeriodId = (period) ? period : this.product.prices[0].id;
              this.product.pricingPeriodId = pricingPeriodId;
              this.backupForm = this.formBuilder.group({
                quantity: [1, [Validators.required, CustomValidator.numbericValidator]],
                regPeriod: null,
                config: new FormArray([]),
                customfields: new FormArray([]),
                pricingPeriod: [pricingPeriodId, Validators.required],
                basePrice: [null, Validators.required],
                setupPrice: [null, Validators.required],
                totalPrice: [null, Validators.required],
                cartId
              });

              for (const cfgk in params) {
                if (params.hasOwnProperty(cfgk) && cfgk.indexOf('cfg') > -1) {
                  let cfgId = cfgk.replace('cfg[', '');
                  cfgId = cfgId.replace(']', '');
                  const configOption = new ProductConfigOption();
                  configOption.configoptionid = parseInt(cfgId, 10);
                  configOption.value = parseInt(params[cfgk], 10);
                  this.configOptions.push(configOption);
                }
              }

              this.product.configoptions.forEach(option => {
                let defVal = this.getValueForOption(option, 'min');
                const ticks = this.getOptionsForCFG(option);
                const slider = this.getValueForOption(option, 'name', defVal);
                const defOption = option.options[0];
                const price = this.getPriceForOption(defOption);
                if (this.configOptions && this.configOptions.length > 0) {
                  const defaultOption = this.configOptions.find(x => x.configoptionid === option.id);
                  defVal = (defaultOption) ? defaultOption.value : defVal;
                }
                this.addOptionItem(option.id, defVal, option.name, slider, price, ticks);
              });

              this.product.customfields.forEach(option => {
                if (option.adminonly === '' && option.showorder === 'on') {
                  const allOptions = option.options.split(',').filter(x => x !== 'none');
                  let options = allOptions;
                  const filters = [];
                  allOptions.forEach(x => {
                    const filterName = x.substr(0, x.indexOf('-'));
                    if (filters.indexOf(filterName) < 0) {
                      filters.push(filterName);
                    }
                  });
                  const defOption = allOptions[0];
                  const defFilterName = defOption.substr(0, defOption.indexOf('-'));
                  if (defFilterName) {
                    options = options.filter(x => x.indexOf(defFilterName) >= 0);
                  }
                  this.addCustomFieldItem(option.id, defOption, option.name, options, allOptions, filters);
                }
              });

              if (cartId !== null) {
                this.setDefaultFromCart(cartId);
              }

              this.updateOptionPrices();
            });
          this.subscription.add(subSingle);
        } else {
          // If product id is not set, get all products
          this.loading.product = true;

          // If the groupId is set in parameters, get products by group
          const groupId = (typeof params.gid !== 'undefined') ? parseInt(params.gid, 10) : null;

          if (groupId) {
            const subGroup = this.productService
              .getProducts(null, null, groupId, this.cartCurrency.code, true)
              .subscribe(products => {
                this.loading.product = false;
                this.backupProducts = products;
                this.product = null;
                this.setPricingDefaults();
              });
            this.subscription.add(subGroup);
          } else {
            // If the groupId is not set in parameters, get all products
            const productSubs = this.productGroups.map((group) => {
              return this.productService
                .getProducts(null, null, group.id, this.cartCurrency.code, true)
                .pipe(take(1));
            });
            const subAll = zip(...productSubs).subscribe((products) => {
              this.loading.product = false;
              this.backupProducts = products.reduce((acc, val) => acc.concat(val), []);
              this.product = null;
              this.setPricingDefaults();
            });
            this.subscription.add(subAll);
          }
        }
      });
    this.subscription.add(routerQidSub);
  }

  setCustomFieldValue(value: string, customFieldControl) {
    const options = customFieldControl.get('allOptions').value.filter(x => x.indexOf(value) >= 0)
    const defOption = options[0];
    customFieldControl.get('options').setValue(options);
    customFieldControl.get('value').setValue(defOption);
  }

  setPricingPeriod(price: {id: number, name: string}) {
    this.product.pricingPeriodId = price.id;
    this.backupForm.patchValue({pricingPeriod: price.id});
    this.backupForm.patchValue({totalPrice: this.getPriceForPeriodId(this.product, price.id)});
  }

  addCustomFieldItem(id: number = null, value: number|string = null, label: string = null,
                     options: string[], allOptions: string[], filters: string[]): void {
    this.cu.push(this.formBuilder.group({
      id: [id, Validators.required],
      value: [value, Validators.required],
      label: [label],
      options: [options],
      filters: [filters],
      allOptions: [allOptions]
    }));
  }

  setDefaultFromCart(cartId: number) {
    const cartItem: CartOrderItem = this.cartService.getCartItemByCartId(cartId);
    if (cartItem) {
      this.backupForm.patchValue({cartId: cartItem.cartId});
      this.backupForm.patchValue({pricingPeriod: cartItem.pricingPeriodId});
      this.product.pricingPeriodId = cartItem.pricingPeriodId;
      cartItem.config.forEach(cfg => {
        const cfgControl = this.c.controls.find((x) => {
          return x.get('id').value === cfg.id;
        });
        cfgControl.get('value').setValue(cfg.value);
      });

      cartItem.customfields.forEach(cfg => {
        const cuControl = this.cu.controls.find((x) => {
          return x.get('id').value === cfg.id;
        });
        cuControl.get('value').setValue(cfg.value);
      });
    }
  }

  addQuantity() {
    this.backupForm.patchValue({quantity: ++this.backupForm.value.quantity});
    this.updateOptionPrices();
  }

  removeQuantity() {
    if (this.backupForm.value.quantity < 2) {
      return false;
    }
    this.backupForm.patchValue({quantity: --this.backupForm.value.quantity});
    this.updateOptionPrices();
  }

  getOptionsForCFG(cfgOption) {
    const ticks: any[] = [];
    cfgOption.options.forEach(opt => {
      ticks.push({
        value: opt.id,
        legend: opt.name,
      });
    });
    const options: Options = {
      showTicks: true,
      showTicksValues: true,
      showSelectionBar: true,
      stepsArray: ticks
    };

    return options;
  }

  updateCfgOption(id, cfg) {
    const label = cfg.controls.ticks.value.stepsArray.find(x => x.value === id.value);
    cfg.controls.slider.setValue(label.legend);
    this.updateOptionPrices();
  }

  getCurrentPeriodPrice(field: string = null) {
    if (!this.product) {
      return false;
    }
    const currentPrice = this.product.prices.find(x => x.id === this.f.pricingPeriod.value);
    if (field) {
      return currentPrice[field];
    }
    return currentPrice;
  }

  getSetupPrice(field: string = null) {
    if (!this.product) {
      return false;
    }
    const currentPeriodPrice = this.getCurrentPeriodPrice();
    const feeSuffix = currentPeriodPrice.name.charAt(0);
    const productCurrencyFee = this.product.pricing[this.cartCurrency.code];
    if (field) {
      const fieldValue = parseFloat(productCurrencyFee[feeSuffix + field]);
      return (fieldValue < 0) ? 0 : fieldValue;
    }
    return productCurrencyFee;
  }

  updateOptionPrices() {
    let totalPrice = 0;

    const basePrice = this.getCurrentPeriodPrice('value');
    this.backupForm.patchValue({basePrice});
    totalPrice += this.f.quantity.value * basePrice;
    this.backupForm.patchValue({basePrice});
    const setupPrice = this.getSetupPrice('setupfee');
    this.backupForm.patchValue({setupPrice});

    this.c.controls.forEach(cntrl => {
      const ctrlId = cntrl.get('id').value;
      const productConfig = this.product.configoptions.find(x => x.id === ctrlId);
      const option = productConfig.options.find(x => x.id === cntrl.value.value);
      const price = this.f.quantity.value * this.getPriceForOption(option);
      totalPrice += price;
      cntrl.get('price').setValue(price);
      cntrl.get('slider').setValue(option.name);
    });

    this.backupForm.patchValue({totalPrice});
  }

  addOptionItem(id: number = null, value: number = null, label: string = null, slider: string = null, price: number = null, ticks: Options)
    : void {
    this.c.push(this.formBuilder.group({
      id: [id, Validators.required],
      value: [value, Validators.required],
      label: [label],
      slider: [slider],
      price: [price],
      ticks
    }));
  }

  getPriceForOption(option: any) {
    const prices = option.pricing[this.cartCurrency.code];
    const pricingPeriod = this.product.prices.find(x => x.id === this.f.pricingPeriod.value);
    const price = parseFloat(prices[pricingPeriod.name]);
    return (price <= 0) ? 0 : price;
  }

  setPricingDefaults(): void {
    this.productService.setPricingDefaults(this.backupProducts);
  }

  getValueForOption(configOption, field: string, id: number = null) {
    const optionIds = configOption.options.map(x => x.id);
    let value;
    switch (field) {
      case 'min':
        value = Math.min(...optionIds);
        break;
      case 'minSlider':
        const m = Math.min(...optionIds);
        const minV = configOption.options.find(x => x.id === m);
        value = minV.name;
        break;
      case 'max':
        value = Math.max(...optionIds);
        break;
      case 'maxSlider':
        const v = Math.max(...optionIds);
        const maxV = configOption.options.find(x => x.id === v);
        value = maxV.name;
        break;
      case 'name':
        value = (id) ? configOption.options.find(x => x.id === id).name : configOption.options.map(x => x.name);
        break;
    }

    return value;
  }

  /**
   * Returns specific description string constructed from products description field specific to backup.
   */
  getDescription(backup: Product): string {
    const description = this.productService.getDescription(backup);
    if (description === '') {
      return this.productService.getDescription(backup);
    }
    return description;
  }

  getName(backup: Product): string {
    return this.productService.getName(backup);
  }

  getPricing(prices: any, selectedId: number, field: string): number | string {
    return this.productService.getPricing(prices, selectedId, field);
  }

  getPriceForPeriodId(backup: Product, pricingPeriodId: number): number {
    return this.productService.getPriceForPeriodId(backup, pricingPeriodId);
  }

  setProduct(backup: Product): void | boolean {
    if (!backup.pricingPeriodId) {
      return false;
    }
    this.router.navigate([], {
      queryParams: {
        gid: backup.gid,
        pid: backup.pid,
        period: backup.pricingPeriodId
      }});
  }

  updateCart(createNew: boolean = false) {
    this.submitted = true;
    if (this.backupForm.invalid) {
      return false;
    }
    const name = this.getName(this.product);
    const desc = this.getProductConfigDescription();
    const group = this.productService.getGroupByGroupId(this.product.gid);
    let cartItem = this.cartService.generateCartItem(this.product, group, name, desc, this.backupForm.value);
    cartItem = this.cartService.addOrderItem(cartItem);
    this.backupForm.patchValue({cartId: cartItem.cartId});
    this.submitted = false;

    return (createNew) ?
      this.router.navigate(['/cart/product/'], {queryParams: {gid: group.id}}) :
      this.router.navigate([`/cart/options/${cartItem.cartId}`]);
  }

  getProductConfigDescription() {
    let desc = '';
    this.backupForm.value.config.forEach((i, idx, cfg) => {
      const divider = ((idx + 1) < this.backupForm.value.config.length) ? ', ' : '';
      desc += `${cfg[idx].label}: ${cfg[idx].slider}${divider}`;
    });
    desc += ', ';
    this.backupForm.value.customfields.forEach((i, idx, cfg) => {
      const divider = ((idx + 1) < this.backupForm.value.customfields.length) ? ', ' : '';
      desc += `${cfg[idx].label}: ${cfg[idx].value}${divider}`;
    });

    return desc;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getProductTranslatedName(prod: Product) {
    if (prod.translated_product_name[this.localeId] !== undefined) {
      return prod.translated_product_name[this.localeId];
    }
    return prod.name;
  }
}
