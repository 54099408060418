import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";

import { catchError, map, take } from "rxjs/operators";
import { Observable } from "rxjs";

import { APP_CONFIG, AppConfig } from "../app-config.module";
import { HandleError, HttpErrorHandler } from "./http-error-handler.service";
import { GeoStruct } from "../_models/geo-struct";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  observe: "response" as "body",
};

@Injectable({
  providedIn: "root",
})
export class GeoStructService {
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError("GeoStructService");
  }

  handleError: HandleError;

  getStates(searchQuery: string): Observable<GeoStruct[]> {
    const data = {
      searchQuery,
    };

    return this.http
      .post<HttpResponse<GeoStruct[]>>(
        `${this.config.apiEndpoint}/public/geo-structs/counties`,
        data,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res.body !== undefined ? res.body : [];
        }),
        take(1),
        catchError(this.handleError("getStates", []))
      );
  }

  getStatesForAPlace(placeId: number): Observable<GeoStruct[]> {
    const data = {};

    return this.http
      .post<HttpResponse<GeoStruct[]>>(
        `${this.config.apiEndpoint}/public/geo-structs/places/${placeId}/counties`,
        data,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res.body !== undefined ? res.body : [];
        }),
        take(1),
        catchError(this.handleError("getStatesForAPlace", []))
      );
  }

  getPlaces(searchQuery: string): Observable<GeoStruct[]> {
    const data = {
      searchQuery,
    };

    return this.http
      .post<HttpResponse<GeoStruct[]>>(
        `${this.config.apiEndpoint}/public/geo-structs/places`,
        data,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res.body !== undefined ? res.body : [];
        }),
        take(1),
        catchError(this.handleError("getPlaces", []))
      );
  }

  getPosts(searchQuery: string): Observable<GeoStruct[]> {
    const data = {
      searchQuery,
    };

    return this.http
      .post<HttpResponse<GeoStruct[]>>(
        `${this.config.apiEndpoint}/public/geo-structs/posts`,
        data,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res.body !== undefined ? res.body : [];
        }),
        take(1),
        catchError(this.handleError("getPosts", []))
      );
  }

  getPostsForAPlace(placeId: number): Observable<GeoStruct[]> {
    const data = {};

    return this.http
      .post<HttpResponse<GeoStruct[]>>(
        `${this.config.apiEndpoint}/public/geo-structs/places/${placeId}/posts`,
        data,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res.body !== undefined ? res.body : [];
        }),
        take(1),
        catchError(this.handleError("getPostsForAPlace", []))
      );
  }

  getPlacesForAPost(postId: number): Observable<GeoStruct[]> {
    const data = {};

    return this.http
      .post<HttpResponse<GeoStruct[]>>(
        `${this.config.apiEndpoint}/public/geo-structs/posts/${postId}/places`,
        data,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res.body !== undefined ? res.body : [];
        }),
        take(1),
        catchError(this.handleError("getPlacesForAPost", []))
      );
  }
}
