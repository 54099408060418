<div class="container-fluid">
  <div class="row pt-1 pt-md-5 bg-background" *ngIf="!showUserData">
    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 box p-2 p-md-3 p-lg-4">
      <h5 class="font-weight-bold mb-3 bd-bottom pb-3" i18n>MyDataKnox prijava korisnika</h5>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!qrLink">
        <div class="form-group">
          <label for="email" i18n>Email</label>
          <input id="email" type="email" formControlName="email" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback w-100">
            <div *ngIf="f.email.errors.required" i18n>Email je obavezno polje</div>
            <div *ngIf="f.email.errors.email" i18n>Email nije ispravan</div>
          </div>
        </div>

        <div class="form-group">
          <label for="password" i18n>Lozinka</label>
          <div class="input-group">
            <input id="password" type="password" formControlName="password" class="form-control"
                   [ngClass]="{ 'is-invalid': submitted && f.password.errors }" [type]="!shown ? 'password' : 'text'"/>
            <div class="input-group-append toggable">
              <div class="input-group-text" (click)="viewInput()">
                <fa-icon tooltip="Vidi lozinku" i18n-tooltip="Vidi lozinku" [icon]="faEyeSlash" *ngIf="!shown"></fa-icon>
                <fa-icon tooltip="Sakrij lozinku" i18n-tooltip="Sakrij lozinku" [icon]="faEye" *ngIf="shown"></fa-icon>
              </div>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback w-100">
              <div *ngIf="f.password.errors.required" i18n>Lozinka obavezna</div>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <a routerLink="/reset-password" class="btn-link text-underline fsmaller" tooltip="Zaboravili ste lozinku?" i18n-tooltip="Zaboravili ste lozinku?" i18n>Zaboravljena lozinka?</a>
        </div>

        <div class="container-fluid text-center">
          <div class="row">
            <app-loader *ngIf="loading"></app-loader>
            <a routerLink="/register" class="order-2 order-md-1 col-12 col-md-5 btn rounded-light btn-bordered" *ngIf="!loading" i18n>Registriraj se</a>
            <button class="order-1 order-md-2 col-12 col-md-5 offset-md-2 mb-3 mb-md-0 btn rounded-light btn-blue" *ngIf="!loading" i18n>Prijavi se</button>
          </div>
        </div>
      </form>

      <form [formGroup]="loginFAForm" (ngSubmit)="submit2FA()" *ngIf="qrLink" class="mt-4">
        <div style="text-align: center;" *ngIf="qrLink !== 'token_already_set'">
          <img src="assets/googleauth.png" style="width:85px"/><br>
          <h1 i18n>Molimo skeniraj QR kod</h1>
          <p i18n>s Google Authenticator aplikacijom</p>

          <!-- <a href="{{qrLink}}" target="_blank"><img src="{{qrLink}}"></a> -->

          <qrcode *ngIf="qrLink && qrLink !== ''" [qrdata]="qrLink" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>

          <p style="padding-bottom: 20px;" i18n>te nakon toga unesi broj s Google autentikatora u polje ispod</p>
        </div>

        <div class="form-group mb-4">
          <img src="assets/googleauth.png" style="width:125px;margin:0 auto;display:block" *ngIf="qrLink == 'token_already_set'"/><br>
          <label for="code" *ngIf="qrLink == 'token_already_set'" i18n>Unesi broj s Google autentikatora</label>
          <input id="code" type="text" formControlName="code" class="form-control"
                 [ngClass]="{ 'is-invalid': fAsubmitted && fg.code.errors }"/>
          <div *ngIf="fAsubmitted && fg.code.errors" class="invalid-feedback w-100">
            <div *ngIf="fg.code.errors.required" i18n>Moraš unijeti broj sa Google autentikatora</div>
            <div *ngIf="fg.code.errors.pattern" i18n>Broj mora sadržavati 6 znamenki</div>
          </div>
        </div>

        <div class="container-fluid text-center">
          <div class="row">
            <app-loader *ngIf="loading"></app-loader>
            <button
              *ngIf="!loading"
              type="button"
              (click)="cancel2FA()"
              class="order-2 order-md-1 col-12 col-md-5 btn rounded-light btn-bordered"
              i18n
            >
              Odustani
            </button>
            <button
              *ngIf="!loading"
              type="submit"
              class="order-1 order-md-2 col-12 col-md-5 offset-md-2 mb-3 mb-md-0 btn rounded-light btn-blue"
              i18n
            >
              Potvrdi
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="showUserData">
    Show user data for loged in user: {{authenticationService.currentUserValue.firstname}} {{authenticationService.currentUserValue.lastname}}
  </div>

</div>
