<div *ngIf="pager?.pages && pager?.totalPages > 1">
    <ul class="pagination flex-center flex-wrap">
        <li [ngClass]="{disabled:pager?.currentPage === 1}" class="page-item first-item">
            <button (click)="handlePageChange(1)" class="page-link" i18n>Prva</button>
        </li>
        <li [ngClass]="{disabled:pager?.currentPage === 1}" class="page-item previous-item">
            <button (click)="handlePageChange(pager?.currentPage - 1)" class="page-link" i18n>Prethodna</button>
        </li>
        <li *ngFor="let page of pager?.pages" [ngClass]="{active:pager?.currentPage === page}" class="page-item number-item">
            <button (click)="handlePageChange(page)" class="page-link">{{page}}</button>
        </li>
        <li [ngClass]="{disabled:pager?.currentPage === pager?.totalPages}" class="page-item next-item">
            <button (click)="handlePageChange(pager?.currentPage + 1)" class="page-link" i18n>Sljedeća</button>
        </li>
        <li [ngClass]="{disabled:pager?.currentPage === pager?.totalPages}" class="page-item last-item">
            <button (click)="handlePageChange(pager?.totalPages)" class="page-link" i18n>Zadnja</button>
        </li>
    </ul>
</div>
