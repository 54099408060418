import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {first} from 'rxjs/operators';

import {AlertService, AuthenticationService} from '../../../_services';
import {CustomValidator} from '../../../_components';
import {ContactService} from '../contact.service';
import {Client} from '../../../_models';
import {CartOrderItem} from '../../../cart/cart-order-item';
import {CartService} from '../../../cart/cart.service';
import {Contact} from '../contact';
import {SystemService} from "../../../_services/system.service";

@Component({
  selector: 'app-contact-new',
  templateUrl: 'contact-new.component.html'
})
export class ContactNewComponent implements OnInit {
  @Input() orderItem: CartOrderItem;
  addContactForm: FormGroup;
  submitted = false;
  currentClient: Client;
  source: string;
  newContact: Contact;
  // At least one lowercase, one uppercase, one special character, and 8-64 valid characters
  public passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\$%@?+\-`!;,.:#*()_\[\]\{\}])[\w\$%@?+\-`!;,.:#*()_\[\]\{\}]{8,64}$/;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ContactService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private systemService: SystemService,
    private cartService: CartService
  ) {
    this.currentClient = this.authenticationService.currentUserValue;
    this.newContact = new Contact();
    this.newContact.persontype = 'private';
    this.newContact.companyname = '';
    this.newContact.phonenumber = null;
  }

  ngOnInit() {
    this.source = this.orderItem !== undefined ? 'cart' : this.source;
    this.addContactForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      companyname: [null],
      address1: [null, Validators.required],
      address2: [null],
      city: [null, Validators.required],
      state: [null],
      postcode: [null, [Validators.required, Validators.minLength(2)]],
      country: [null, Validators.required],
      phonenumber: [this.newContact.phonenumber],
      telephoneNumber: [this.newContact.phonenumber, Validators.required],
      tax_id: [null, [Validators.minLength(5)]],
      password: [null, [Validators.minLength(8), Validators.maxLength(64), Validators.pattern(this.passPattern)]],
      generalemails: [false],
      productemails: [false],
      domainemails: [false],
      invoiceemails: [false],
      supportemails: [false],
      subaccount: [false],
      password2: [null, [Validators.minLength(8), Validators.maxLength(64), Validators.pattern(this.passPattern)]]
    });
  }

  togglePassRequired(required) {
    if (required) {
      this.addContactForm.get('password2').setValidators(
        [Validators.required, Validators.minLength(8), Validators.maxLength(64), Validators.pattern(this.passPattern)]);
    } else {
      this.addContactForm.get('password2').setValidators(
        [Validators.minLength(8), Validators.maxLength(64), Validators.pattern(this.passPattern)]);
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addContactForm.invalid) {
      return;
    }
    const data = this.addContactForm.value;
    let permissions: string[] = [];
    for (const key in data.permissions) {
      if (data.permissions.hasOwnProperty(key)) {
        if (data.permissions[key]) {
          permissions.push(key);
        }
      }
    }

    if (!data.subaccount) {
      permissions = [];
    }
    data.permissions = permissions.join();

    this.service.add(data)
      .pipe(first())
      .subscribe(contactId => {
          if (contactId) {
            this.alertService.success($localize`Kontakt je uspješno kreiran`, true);
            if (this.orderItem === undefined) {
              this.router.navigate(['/profile/contacts']);
            } else {
              this.orderItem.contactId = contactId;
              this.cartService.updateOrderItem(this.orderItem, true, false);
            }
          } else {
            this.alertService.error($localize`Kontakt nije spremljen. Provjeri podatke i pokušaj ponovno.`);
          }
        },
        error => {
          this.alertService.error(error);
        });
  }

  generateRootPassword() {
    const newPass = this.systemService.genRandomPattern(this.passPattern.source);
    this.addContactForm.patchValue({password2: newPass});
  }
}
