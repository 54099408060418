import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';

import {Client} from '../../_models';
import {AlertService, AuthenticationService, ClientService} from '../../_services';
import { SystemService } from '../../_services/system.service';

@Component({
  templateUrl: 'profile-edit.component.html'
})
export class ProfileEditComponent implements OnInit, OnDestroy {
  user: Client;
  parentForm: FormGroup;
  submitted = false;
  title = '';
  private subscription: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private service: ClientService,
    private alertService: AlertService,
    private router: Router,
    private systemService: SystemService,
    protected authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    if (this.user.subaccount) {
      return this.router.navigate(['/profile/contacts/' + this.user.id]);
    }
    this.user.customfields = (typeof this.user.customfields !== 'undefined') ? btoa(this.user.customfields) : null;
    this.title += `: ${this.user.firstname} ${this.user.lastname}`;

    this.parentForm = this.formBuilder.group({
      email: [{value: this.user.email, disabled: this.disableInput('email')}, [Validators.required, Validators.email]],
      firstname: [{value: this.user.firstname, disabled: this.disableInput('firstname')}, Validators.required],
      lastname: [{value: this.user.lastname, disabled: this.disableInput('lastname')}, Validators.required],
      companyname: [{value: this.user.companyname, disabled: this.disableInput('companyname')}],
      address1: [{value: this.user.address1, disabled: this.disableInput('address1')}, Validators.required],
      address2: [{value: this.user.address2, disabled: this.disableInput('address2')}],
      city: [{value: this.user.city, disabled: this.disableInput('city')}, Validators.required],
      state: [{value: this.user.state, disabled: this.disableInput('state')}, Validators.required],
      postcode: [{value: this.user.postcode, disabled: this.disableInput('postcode')},
        [Validators.minLength(2)]],
      country: [{value: this.user.country, disabled: this.disableInput('country')}, Validators.required],
      phonenumber: [{value: this.user.phonenumber, disabled: this.disableInput('phonenumber')}, Validators.required],
      telephoneNumber: [{value: this.user.telephoneNumber, disabled: this.disableInput('telephoneNumber')},
        Validators.required],
      tax_id: [{value: this.user.tax_id, disabled: this.disableInput('tax_id')},
        [Validators.minLength(5)]],
      language: [{value: this.user.language, disabled: this.disableInput('language')}, Validators.required],
      marketingoptin: [{value: this.user.marketingoptin, disabled: this.disableInput('marketingoptin')}],
      notes: [{value: this.user.notes, disabled: this.disableInput('notes')}],
      clientip: [{value: this.user.clientip, disabled: this.disableInput('clientip')}],
      twofa: [{value: this.user.twofa, disabled: this.disableInput('twofa')}],
      persontype: [{value: this.user.customfields.persontype, disabled: this.disableInput('persontype')}],
      customfields1: [{value: this.user.customfields1, disabled: this.disableInput('customfields1')}],
      customfields2: [{value: this.user.customfields2, disabled: this.disableInput('customfields2')}, Validators.email],
    });
    
    this.parentForm.get('persontype').disable();
    this.parentForm.get('companyname').disable();
  }

// convenience getter for easy access to form fields
  get f() {
    return this.parentForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // If e-bill email is empty, set it to the same value as the email field
    const eBillEmail = this.parentForm.controls.customfields2.value ? this.parentForm.controls.customfields2.value : this.parentForm.controls.email.value;
    const eBillOptIn = this.parentForm.controls.customfields1.value;

    this.parentForm.patchValue({
      phonenumber: this.parentForm.controls.telephoneNumber.value.internationalNumber,
      customfields2: eBillOptIn ? eBillEmail : '', // If eBillOptIn is false, set eBillEmail to empty string
    });

    // stop here if form is invalid
    if (this.parentForm.invalid) {
      return;
    }

    // Check form against forbidden fields and reset their field (set from user variables)
    for (const key in this.parentForm.value) {
      if (this.parentForm.value.hasOwnProperty(key) && this.disableInput(key) && this.parentForm.value[key] !== this.user[key]) {
        this.parentForm.value[key] = this.user[key];
      }
    }

    const dataToSubmit = this.parentForm.value;
    const customFields = this.systemService.clientCustomFieldsValue;

    // Merge custom fields under 'customfields' key
    // For each through customfields in form and add them to dataToSubmit.customfields
    dataToSubmit.customfields = [];
    for (const key in this.parentForm.value) {
      if (
        this.parentForm.value.hasOwnProperty(key) &&
        key.includes("customfields")
      ) {
        const customField = customFields.customFields.find(
          (field) => field.systemname === key
        );
        if (customField?.id) {
          dataToSubmit.customfields.push({
            id: customField.id,
            value: this.parentForm.value[key],
          });
        }
      }
    }

    const updateAction = this.service.update(dataToSubmit)
      .subscribe(resp => {
          if (resp) {
            this.alertService.success($localize`Izmjene su uspješno spremljene`, true);
            // map form values to user model
            for (const key in dataToSubmit) {
              if (dataToSubmit.hasOwnProperty(key)) {
                this.user[key] = dataToSubmit[key];
              }
            }
            const phoneNumber = (this.user.phonenumber !== '') ? this.user.phonenumber.split('.') : '';
            this.user.phonenumberformatted = this.user.phonenumber;
            this.user.phonenumber = phoneNumber[1];

            this.authenticationService.updateUser(this.user);

            this.router.navigate(['/profile']);
          }
        },
        error => {
          this.alertService.error(error);
        });
    this.subscription.add(updateAction);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Checks against forbidden fields array should a certain field be disabled or not.
   * Forbidden field is permitted only if value doesn't exist (is null)
   * @param fieldName string
   */
  disableInput(fieldName: string) {
    return this.user.forbiddenFields.indexOf(fieldName) > -1 && this.user[fieldName] !== null;
  }
}
