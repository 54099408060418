import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Pager} from '../tickets/pager';

@Component({
  selector: 'app-non-route-pager',
  templateUrl: './non-route-pager.component.html'
})
export class NonRoutePagerComponent {
  @Input() pager: Pager;

  @Output() onPageChange = new EventEmitter<number>();

  public handlePageChange(newPage: number): void {
    this.onPageChange.emit(newPage);
  }
}
