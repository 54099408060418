import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {HandleError, HttpErrorHandler} from '../../_services';
import {Invoice} from './invoice';
import {Pager} from '../../support/tickets/pager';
import {InvoiceTransaction} from './invoice-transaction';
import {InvoiceCachedData} from './invoice-cached-data';
import {InvoiceOwner} from './invoice-owner';
import {TicketCounts} from '../../support/tickets/ticket-counts';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  handleError: HandleError;
  private invoicesSubject: BehaviorSubject<Invoice[]>;
  public invoices$: Observable<Invoice[]>;

  /**
   * Returns translated bills status by status key
   * @param status string
   */
  public static getBillStatusLabelByStatusKey(status: string) {
    switch (status.toLowerCase()) {
      case 'all': {
        return $localize`Sve`;
      }
      case 'paid': {
        return $localize`Plaćeno`;
      }
      case 'unpaid': {
        return $localize`Neplaćeno`;
      }
      case 'cancelled': {
        return $localize`Otkazano`;
      }
      default : {
        return status;
      }
    }
  }

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    this.handleError = httpErrorHandler.createHandleError('InvoiceService');
    this.invoicesSubject = new BehaviorSubject<Invoice[]>([]);
    this.invoices$ = this.invoicesSubject.asObservable();
  }

  setInvoices(invoices: Invoice[]) {
    this.invoicesSubject.next(invoices);
  }

  /**
   * Returns a list of invoices by filterData
   * @param filterData Can contain search parameters such as status, orderby, order, userid, page
   * @param pageSize Determines the size of page size for display
   */
  getList(filterData: {}, pageSize: number | 10): Observable<{ pager: Pager, invoices: Invoice[] }> {
    const pager = new Pager();
    const keyPage = 'page';
    const keyStatus = 'status';
    const keyOrderBy = 'orderby';
    const keyOrder = 'order';
    const allowedOrdering = [
      'id',
      'date',
      'duedate',
      'status',
      'total'
    ];
    const status = filterData.hasOwnProperty(keyStatus) ? filterData[keyStatus] : null;
    const orderby = filterData.hasOwnProperty(keyOrderBy) && allowedOrdering.includes(filterData[keyOrderBy]) ?
      filterData[keyOrderBy] : null;
    const order = filterData.hasOwnProperty(keyOrder) ? filterData[keyOrder] : 'asc';
    pager.currentPage = filterData.hasOwnProperty(keyPage) ? parseInt(filterData[keyPage], 10) : 1;
    pager.pageSize = pageSize;

    const startIndex = (pager.currentPage === 1) ? 0 : (pageSize * (pager.currentPage - 1));

    const data = {
      action: 'GetInvoices',
      cache: true,
      limitstart: startIndex,
      limitnum: pageSize,
      order,
      orderby
    };

    if (status && status !== 'all') {
      data[keyStatus] = status;
    }

    return this.http.post<HttpResponse<Invoice[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          if (res.body.invoices !== undefined) {
            pager.totalPages = Math.ceil(res.body.totalresults / pageSize);
            pager.pages = [...Array(Math.ceil(res.body.totalresults / pageSize)).keys()].map(i => (i + 1));
            const tmpInvoices = typeof res.body.invoices !== 'undefined' ? res.body.invoices.filter(x => x.status !== 'Draft') : null;

            let invoices = [];
            for (const invoicesKey in tmpInvoices) {
              if (tmpInvoices.hasOwnProperty(invoicesKey)) {
                for (const tmpKey in tmpInvoices[invoicesKey]) {
                  if (tmpInvoices[invoicesKey].hasOwnProperty(tmpKey)) {
                    if (tmpKey === 'cached_data') {
                      const tmpKeyOverride = (tmpKey === 'cached_data') ? 'cachedData' : tmpKey;
                      const cachedData = new InvoiceCachedData();
                      cachedData.suffix = tmpInvoices[invoicesKey][tmpKey].suffix;
                      cachedData.prefix = tmpInvoices[invoicesKey][tmpKey].prefix;
                      cachedData.invoiceowner = tmpInvoices[invoicesKey][tmpKey].invoiceowner;
                      cachedData.currencyid = tmpInvoices[invoicesKey][tmpKey].currencyid;
                      tmpInvoices[invoicesKey][tmpKeyOverride] = cachedData;
                    } else {
                      tmpInvoices[invoicesKey][tmpKey] = tmpInvoices[invoicesKey][tmpKey];
                    }
                  }
                }
              }
            }
            invoices = tmpInvoices;

            return {
              pager,
              invoices
            };
          }
          return [];
        }),
        catchError(this.handleError('getList', null))
      );
  }

  getConfigurationValue(): Observable<any> {
    const data = {
      action: 'GetConfigurationValue',
      setting: 'InvoicePayTo',
      cache: true
    };

    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(this.handleError('getConfigurationValue', null))
      );
  }

  /**
   * Gets an invoice by id.
   * @param id Invoice/bill id
   */
  getById(id: number): Observable<Invoice> {
    const data = {
      action: 'GetInvoice',
      invoiceid: id,
      language: this.localeId,
      cache: false
    };

    return this.http.post<HttpResponse<string | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          return res.body as Invoice;
        }),
        catchError(this.handleError('getById', null))
      );
  }

  getInvoiceFile(invoiceid: number) {
    const data = {
      action: 'GetInvoiceFile',
      invoiceid
    };
    return this.http.post<HttpResponse<string | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(this.handleError('getInvoiceFile', null))
      );
  }

  /**
   * Updates invoice payment method only for 'Unpaid' invoices
   * @param invoiceid number
   * @param paymentmethod string
   */
  updateInvoicePaymentMethod(invoiceid: number, paymentmethod: string): Observable<Invoice> {
    const data = {
      action: 'UpdateInvoice',
      invoiceid,
      paymentmethod,
      cache: false,
      invalidateKeys: ['GetInvoice']
    };

    return this.http.post<HttpResponse<Invoice | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          return (typeof res.body.result !== 'undefined' && res.body.result === 'success');
        }),
        catchError(this.handleError('updateInvoice', null))
      );
  }

  /**
   * Updates invoice owner data.
   * @param invoiceId number
   * @param invoiceOwner InvoiceOwner
   */
  updateInvoiceData(invoiceId: number, invoiceOwner: InvoiceOwner): Observable<{ result: string, message: string, newdata: InvoiceOwner }> {
    const data = {
      action: 'UpdateInvoiceData',
      invoiceid: invoiceId,
      firstname: invoiceOwner.firstname,
      lastname: invoiceOwner.lastname,
      companyname: invoiceOwner.companyname,
      taxid: invoiceOwner.taxid,
      tax_id: invoiceOwner.tax_id,
      address1: invoiceOwner.address1,
      address2: invoiceOwner.address2,
      city: invoiceOwner.city,
      state: invoiceOwner.state,
      postcode: invoiceOwner.postcode,
      country: invoiceOwner.country,
      countrycode: invoiceOwner.countrycode,
      countryname: invoiceOwner.countryname,
    };
    return this.http.post<HttpResponse<{ result: string, message: string, newdata: InvoiceOwner }
      | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(this.handleError('updateInvoiceData', null))
      );
  }

  /**
   * Returns transactions for invoice id.
   * @param invoiceId Id of invoice to return transactions for.
   */
  getInvoicesTransactionsByInvoiceId(invoiceId: number): Observable<InvoiceTransaction[]> {
    const data = {
      action: 'GetTransactions',
      invoiceid: invoiceId
    };

    return this.http.post<HttpResponse<InvoiceTransaction[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          if (res.body.transactions !== undefined) {
            return res.body.transactions.transaction;
          }
          return null;
        }),
        catchError(this.handleError('getInvoicesTransactionsByInvoiceId', null))
      );
  }

  /**
   * Creates invoice
   * @param invoice Invoice
   */
  public createInvoice(invoice: Invoice): Observable<{ result: string, invoiceid: number, status: string }> {
    if (invoice.items === undefined || invoice.items === null || invoice.items.length <= 0) {
      return of({
        result: 'error',
        invoiceid: -1,
        status: 'Items missing'
      });
    }
    const data = {
      action: 'CreateInvoice',
      status: invoice.status,
      draft: invoice.draft,
      sendinvoice: invoice.sendinvoice ? '1' : '0',
      paymentmethod: invoice.paymentmethod,
      taxrate: invoice.taxrate,
      taxrate2: invoice.taxrate2,
      date: invoice.date,
      duedate: invoice.duedate,
      notes: invoice.notes ? invoice.notes : '',
      autoapplycredit: invoice.autoapplycredit ? '1' : '0',
      isAddFunds: invoice.isAddFunds ? '1' : '0'
    };
    let index = 1;
    for (const item of invoice.items) {
      data[`itemdescription${index}`] = item.description;
      data[`itemamount${index}`] = item.amount;
      data[`itemtaxed${index}`] = item.taxed;
      index++;
    }
    return this.http.post<HttpResponse<{ result: string, invoiceid: number, status: string } | any>>(
      `${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('createInvoice', null))
      );
  }

  /**
   * Applies credit to unpaid invoice.
   * @param invoiceid number
   * @param amount number
   * @param noemail boolean
   */
  public applyCreditToInvoice(invoiceid: number, amount: number, noemail: boolean | false):
    Observable<{ result: string, invoiceid: number, amount: number, invoicepaid: boolean }> {
    const data = {
      action: 'ApplyCredit',
      invoiceid,
      amount,
      noemail
    };
    return this.http.post<HttpResponse<{ result: string, invoiceid: number, amount: number, invoicepaid: boolean } | any>>(
      `${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('applyCreditToInvoice', null))
      );
  }

  /**
   * TODO: not used, but leave it here after testing, just to be sure we don't need it any more
   * Adds transaction to invoice
   * @param invoice Invoice object
   * @param transid The unique transaction id for this payment
   * @param date The date of the transaction in your Localisation Format (eg DD/MM/YYYY)
   * @param currencyid The currency id for the transaction if not associated with a user
   * @param amountin The amount received by the payment
   * @param fees The amount of fee charged on the transaction by the merchant - This can be negative
   * @param rate The exchange rate for the payment based on the default currency
   * @param description string
   */
  public addTransaction(invoice: Invoice, transid: string, date: string, currencyid: number,
                        amountin: number, fees: number, rate: number,
                        description: string): Observable<boolean> {
    const data = {
      action: 'AddTransaction',
      paymentmethod: invoice.paymentmethod,
      userid: invoice.userid,
      invoiceid: invoice.id,
      transid,
      date,
      amountin,
      fees,
      rate,
      description
    };
    return this.http.post<HttpResponse<{ result: string } | any>>(`${this.config.apiEndpoint}/user/request`,
      data, httpOptions)
      .pipe(
        map((res) => {
          return res.body.result !== undefined && res.body.result === 'success';
        }),
        catchError(this.handleError('addTransaction', false))
      );
  }

  /**
   * Adds payment to a given invoice.
   * @param invoice number
   * @param transid The unique transaction ID that should be applied to the payment.
   * @param date The date that the payment should have assigned. Format: YYYY-MM-DD HH:mm:ss
   * @param gateway The gateway used, in system name format (for example, paypal or authorize).
   * @param amount The amount paid. You can leave this undefined to take the full amount of the invoice.
   * @param fees The amount of the payment that was taken as a fee by the gateway.
   * @param noemail Set this to true to prevent sending an email for the invoice payment.
   * @param ccType Set this for payment method type (ex maestro)
   */
  public addInvoicePayment(invoice: Invoice, transid: string, date: string, gateway: string, amount: number,
                           fees: number, noemail: boolean = false, ccType: string = null): Observable<boolean> {
    const data = {
      action: 'AddInvoicePayment',
      invoiceid: invoice.id,
      transid,
      gateway,
      date,
      amount,
      fees,
      noemail,
      cc_type: ccType
    };
    return this.http.post<HttpResponse<{ result: string } | any>>(`${this.config.apiEndpoint}/user/request`,
      data, httpOptions)
      .pipe(
        map((res) => {
          return res.body.result !== undefined && res.body.result === 'success';
        }),
        catchError(this.handleError('addTransaction', false))
      );
  }

  public addRemoteTokenPayment(invoice: Invoice, clientid: number, paymethodid: number): Observable<boolean> {
    const data = {
      action: 'AddRemoteTokenPayment',
      clientid,
      paymethodid,
      invoiceid: invoice.id
    };
    return this.http.post<HttpResponse<{ result: string, transactionid: string } | any>>
    (`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body.result !== undefined && res.body.result === 'success';
        }),
        catchError(this.handleError('addRemoteTokenPayment', false))
      );
  }
}
