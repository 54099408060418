import {AfterViewInit, Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {fromEvent, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, first, map, mergeMap} from 'rxjs/operators';
import {faSpinner, faUser, faGlobe} from '@fortawesome/free-solid-svg-icons';

import {AuthenticationService, PrintService} from './_services';
import {CartService} from './cart/cart.service';
import {Client} from './_models';
import {Currency} from './_models/currency';
import {TicketCounts} from './support/tickets/ticket-counts';
import {Invoice} from './accounting/bills/invoice';
import {Product} from './_models/product';
import {ProductService} from './_services/product.service';
import {DomainService} from './domains/domain.service';
import {Domain} from './domains/domain';
import {SystemService} from './_services/system.service';
import {HttpCacheService} from './_services/http-cache.service';
import {TicketService} from './support/tickets';
import {InvoiceService} from './accounting/bills/invoice.service';
import {APP_CONFIG, AppConfig} from './app-config.module';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  wpSite: string;
  wpFaq: string;
  languageList: {code: string, label: string}[];
  loading = {
    products: true,
    notifications: true
  };
  currentUser: Client;
  cartCurrency: Currency;
  cartItems = 0;
  cartDomains = 0;
  faUser = faUser;
  faGlobe = faGlobe;
  faSpinner = faSpinner;
  menuHeight;
  menuCollapse = {
    profile: true,
    support: true
  };
  resizeObservable$: Observable<Event>;

  products: Product[] = [];
  domains: Domain[] = [];
  currentLanguage;
  contentClass: '';
  menuActive = false;
  ticketCounts: TicketCounts;
  invoices: Invoice[];
  public innerWidth: any;
  private subscription: Subscription = new Subscription();
  initialNonceCheck = false;

  @HostListener('window:storage', ['$event']) onStorageChange(event) {
    if (this.authService.onStorageChange(event)) {
      const returnUrl = this.route.snapshot.queryParams.returnUrl || '/login';
      return this.router.navigate([returnUrl]);
    }
  }

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    @Inject(APP_CONFIG) private config: AppConfig,
    public router: Router,
    private route: ActivatedRoute,
    public authService: AuthenticationService,
    private cartService: CartService,
    public printService: PrintService,
    private systemService: SystemService,
    private productService: ProductService,
    private domainService: DomainService,
    private cookieService: CookieService,
    private cacheService: HttpCacheService,
    private ticketCountsS: TicketService,
    private invoicesS: InvoiceService
  ) {
    this.resizeObservable$ = fromEvent(window, 'resize').pipe(debounceTime(200), distinctUntilChanged());
    this.setMenuHeight();
    this.subscription.add(
      this.resizeObservable$.subscribe( _ => {
        this.setMenuHeight();
      })
    );

    this.languageList = this.systemService.getConfig('languageList');
    this.wpSite = this.systemService.getConfig('wpLink');
    this.wpFaq = this.systemService.getConfig('faqLink');
    this.subscription.add(
      this.authService.currentUser
        .subscribe(user => {
          if (user === null) {
            this.checkForNonceParams();
          }
          if (user) {
            if ((this.authService.currentCurrencyValue === null || this.authService.currentCurrencyValue.id !== user.currency)
              || (typeof user.currency !== 'undefined' && !this.authService.currentCurrencyValue)) {
              this.loadCurrency(user.currency);
            }
          }
          this.cartCurrency = this.authService.currentCurrencyValue;
          return this.currentUser = user;
        })
    );

    this.cartCurrency = this.authService.currentCurrencyValue;
    this.subscription.add(
      this.cartService.orderItems$
        .subscribe(cartProducts => {
          this.cartItems = (cartProducts === null) ? 0 : this.cartService.getCartItemCount('orderItems');
        })
    );
    this.subscription.add(
      this.cartService.domainItems$
        .subscribe(cartDomains => {
          this.cartDomains = (cartDomains === null) ? 0 : this.cartService.getCartItemCount();
        })
    );

    this.currentLanguage = this.languageList.find(lang => lang.code === this.localeId);

    this.checkForAffiliateCookie();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.authService.addStorage('REQUESTING_SHARED_CREDENTIALS_KNOX', Date.now().toString());
    this.authService.removeStorage('REQUESTING_SHARED_CREDENTIALS_KNOX');
    this.subscription.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      ).subscribe(data => {
        if (typeof data.contentbg !== 'undefined') {
          this.contentClass = data.contentbg;
        }
      })
    );

    if (this.cartCurrency === null) {
      this.loadCurrency();
    } else if (this.currentUser !== null && typeof this.currentUser.currency !== 'undefined'
      && this.currentUser.currency !== this.cartCurrency.id) {
      this.loadCurrency(this.currentUser.currency);
    }

    this.subscription.add(
      this.authService.currentUser
        .subscribe(user => {
          if (user) {
            const startupMethods = this.systemService.getStartupRequests({})
              .subscribe((res) => {
                this.loading.notifications = false;
                this.loading.products = false;
                if (!res) {
                  this.logout();
                }
                this.loading.products = false;
                if (user.permissions.indexOf('products') >= 0) {
                  this.products = res.GetClientsProducts;
                  this.productService.setClientHasProductGroups(res.hasProductGroups);
                }
                if (user.permissions.indexOf('domains') >= 0) {
                  this.domains = res.GetClientsDomains;
                }
              });
            this.subscription.add(startupMethods);

            this.subscription.add(
              this.ticketCountsS.ticketCount$.subscribe(ticketCounts => {
                if (ticketCounts) {
                  this.ticketCounts = ticketCounts;
                }
              })
            );

            this.subscription.add(
              this.invoicesS.invoices$.subscribe(invoices => {
                if (invoices && invoices.length > 0) {
                  this.invoices = invoices.filter(x => x.status === 'Unpaid');
                }
              })
            );
            this.cartService.transferStorageToUser(user);
          }
        })
    );
  }

  ngAfterViewInit() {
    let selectBgPath = getComputedStyle(document.documentElement).getPropertyValue('--select-bg-path');
    selectBgPath = selectBgPath.replace('/assets', `/${this.localeId}/assets`);
    document.documentElement.style.setProperty('--select-bg-path', selectBgPath);

    let bgBackground = getComputedStyle(document.documentElement).getPropertyValue('--bg-background');
    bgBackground = bgBackground.replace('/assets', `/${this.localeId}/assets`);
    document.documentElement.style.setProperty('--bg-background', bgBackground);

    let searchCheck = getComputedStyle(document.documentElement).getPropertyValue('--search-check');
    searchCheck = searchCheck.replace('/assets', `/${this.localeId}/assets`);
    document.documentElement.style.setProperty('--search-check', searchCheck);
  }

  setMenuHeight() {
    this.menuHeight = window.innerHeight - 2;
  }

  /**
   * Loads currency by id
   * TODO: Replace default value for non logged in user by user location
   * @param newCurrencyId Currency id to load
   */
  loadCurrency(newCurrencyId: number = environment.defaultCurrency) {
    this.subscription.add(
      this.systemService.getCurrencies()
        .subscribe((currencies: Currency[]) => {
            for (const currency of currencies) {
              if (currency.id === newCurrencyId) {
                this.authService.setCurrency(currency);
              }
            }
          }
        )
    );
  }

  toggleMenu(skip: string = null) {
    if (!skip || skip === 'profile') {
      this.menuCollapse.support = true;
    }
    if (!skip || skip === 'support') {
      this.menuCollapse.profile = true;
    }
    if (this.innerWidth > 768) {
      return;
    }
    this.menuActive = !this.menuActive;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  getLocale() {
    return this.localeId;
  }

  goToCart() {
    this.router.navigateByUrl('cart/overview');
  }


  userHasProduct(productType: string[]) {
    let hasProduct = false;
    if (productType.indexOf('domain') >= 0) {
      hasProduct = this.domains && this.domains.length > 0;
    }

    if (!hasProduct) {
      hasProduct = this.productService.clientHasProductGroup(productType);
    }
    return hasProduct;
  }

  getLogoSrc() {
    return `/${this.localeId}/assets/MDK_logo_jednotonski_pozitiv.svg`;
  }

  logout() {
    this.authService.logout(null);
    this.cacheService.invalidateCache();
    this.systemService.resetSubscriptions();
    this.productService.resetSubscriptions();
    this.router.navigate(['/login']);
  }

  private checkForNonceParams() {
    if (this.initialNonceCheck) {
      return;
    }
    this.subscription.add(
      this.route.queryParams.subscribe(res => {
        if (res.returnUrl && res.returnUrl.indexOf('nonce') >= 0) {
          const query = res.returnUrl.split('?');
          if (query.length > 0) {
            const params = this.getQueryStringParams(query[1]);
            if (params.nonce !== undefined) {
              const nonce = params.nonce;
              this.initialNonceCheck = true;
              let clearNonce = true;
              if (params.clear !== undefined) {
                clearNonce = params.clear === 'true';
                // clear nonce and clear params
                delete params.clear;
                delete params.nonce;
              }

              this.authService.loginUserByNonce(nonce)
                .pipe(first())
                .subscribe(
                  user => {
                    this.router.navigate(['/home']);
                    return;
                  },
                  error => {
                    this.router.navigate(['/']);
                    return;
                  })
              return;
            }
          }
          this.router.navigate(['/']);
          return;
        }
      })
    );
  }

  getQueryStringParams = query => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
            const [key, value] = param.split('=');
            params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
            return params;
          }, {}
        )
      : {}
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Updates user language and redirects
   * @param language string
   */
  setLanguage(language: { code: string; label: string }) {
    if (this.currentUser !== null) {
      this.subscription.add(
        this.authService.updateUserLanguage(language.code)
          .subscribe(user => {
            if (user) {
              this.currentUser.language = language.label;
              this.authService.updateUser(this.currentUser);
            }
          })
      );
    }
    window.location.href = this.config.appRoot + '/' + language.code + this.router.url;
  }

  private checkForAffiliateCookie(): void {
    const affiliateId = this.cookieService.get("affiliate_id");

    // If none of the affiliate params are set, return
    if (!affiliateId) {
      this.authService.removeStorage(CartService.affKey);
      return;
    }

    // If affiliate cookie is set, add it to the storage
    const existingAffiliate = JSON.parse(
      this.authService.getStorage(CartService.affKey)
    );
    if (
      !existingAffiliate ||
      existingAffiliate.id !== parseInt(affiliateId, 10)
    ) {
      this.authService.addStorage(
        CartService.affKey,
        JSON.stringify({
          id: parseInt(affiliateId, 10),
          createdAt: Date.now().toString(),
        })
      );
    }
  }
}
