import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {AuthenticationService, HandleError, HttpErrorHandler} from '../_services';
import {Order} from './order';
import {Promotion} from './promotion';
import {CartOrderItem} from './cart-order-item';
import {ProductDomainItem} from './product-domain-item';
import {CartService} from './cart.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public static orderKey = 'order';
  private orderSource = new BehaviorSubject<Order>(null);
  order$: Observable<Order>;
  private readonly handleError: HandleError;

  constructor(
    private http: HttpClient,
    private cartService: CartService,
    httpErrorHandler: HttpErrorHandler,
    private auth: AuthenticationService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError('OrderService');

    const order: Order = JSON.parse(this.auth.getStorage(OrderService.orderKey));
    this.orderSource = new BehaviorSubject<Order>(order);
    this.order$ = this.orderSource.asObservable();
  }

  public saveOrder(order: Order) {
    this.auth.addStorage(OrderService.orderKey, JSON.stringify(order));
    this.orderSource.next(order);
  }

  public getPaymentMethods() {
    const data = {
      action: 'GetPaymentMethods'
    };

    return this.http.post<HttpResponse<Promotion | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          return res.body;
        }),
        catchError(this.handleError('getPaymentMethods', null))
      );
  }

  public createOrder(order: Order, items: CartOrderItem[], domains: ProductDomainItem[]): Observable<Order> {
    const data = {
      ...order, ...{
        action: 'AddOrder',
        promocode: order.coupon,
        items,
        domains
      }
    };

    return this.http.post<HttpResponse<Order>>
    (`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          order.invoiceid = (res.body.invoiceid !== undefined) ? res.body.invoiceid : null;
          if (order.invoiceid) {
            this.cartService.clearAllProducts();
            this.auth.removeStorage(OrderService.orderKey);
            this.orderSource.next(null);
          }
          return order;
        }),
        catchError(this.handleError('createOrder', null))
      );
  }
}
