import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, publishLast, refCount, take} from 'rxjs/operators';
import * as _ from 'lodash';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {Product} from '../_models/product';
import {HandleError, HttpErrorHandler} from './http-error-handler.service';
import {ProductGroup} from '../_models/product-group';
import {ProductUpgradePackage} from '../_models/product-upgrade-package';
import {ProductUpgradeResponse} from '../_models/product-upgrade-response';
import {Options} from '@m0t0r/ngx-slider';
import { LicenseStats } from '../_models/license-stats';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    this.handleError = httpErrorHandler.createHandleError('ProductService');
    this.productSets = this.config.productSets;
    this.productSetsCartView = this.config.productSetsCartView;
  }
  private readonly handleError: HandleError;
  private productGroupSubscription;
  private systemProductsSubscription;
  private productsSubscription;
  private productSubscription;
  clientHasPoductGroup = [];
  products: Product[];
  productSets;
  productSetsCartView;
  productGroups: ProductGroup[];
  productSubscriptionData = {
    limitstart: null,
    limitnum: null,
    serviceid: null,
    serviceName: null
  };

  /**
   * Returns translated product status by status key
   * @param status string
   */
  public static getProductStatusLabelByStatusKey(status: string) {
    switch (status.toLowerCase()) {
      case 'active': {
        return $localize`Aktivno`;
      }
      case 'pending': {
        return $localize`Na čekanju`;
      }
      case 'completed': {
        return $localize`Završeno`;
      }
      case 'suspended': {
        return $localize`Suspendirano`;
      }
      case 'terminated': {
        return $localize`Ukinuto`;
      }
      case 'cancelled': {
        return $localize`Otkazano`;
      }
      case 'inactive': {
        return $localize`Neaktivno`;
      }
      case 'fraud': {
        return $localize`Prevara`;
      }
      case 'redemption' : {
        return $localize`Redemption period`;
      }
      case 'pending registration' : {
        return $localize`Registracija na čekanju`;
      }
      case 'pending transfer' : {
        return $localize`Transfer na čekanju`;
      }
      case 'grace' : {
        return $localize`Grace period`;
      }
      case 'expired' : {
        return $localize`Isteklo`;
      }
      case 'transferred away' : {
        return $localize`Transferirano izvan MDK`;
      }
      case 'all' : {
        return $localize`Sve`;
      }

      default : {
        return status;
      }
    }
  }

  resetSubscriptions() {
    this.productsSubscription = undefined;
    if (this.productSubscription !== undefined) {
      this.productSubscription = undefined;
    }
    if (this.systemProductsSubscription !== undefined) {
      this.systemProductsSubscription = undefined;
    }
    this.products = undefined;
  }

  /**
   * Sets default pricing period id for products
   * @param products Product[]
   */
  public setPricingDefaults(products: Product[]): void {
    products.forEach(x => {
      x.pricingPeriodId = parseInt(String(x.prices[0].id), 10);
    });
  }

  /**
   * Returns translated product name
   * @param product Product
   */
  public getName(product: Product): string {
    return (typeof product.translated_product_name[this.localeId]) ?
      product.translated_product_name[this.localeId] : product.name;
  }

  /**
   * Returns pricing value or name for selected pricing id.
   * @param prices any
   * @param selectedId number
   * @param field string
   */
  public getPricing(prices: any, selectedId: number, field: string): number | string {
    let result;
    const price = (selectedId) ? prices.find(x => x.id === selectedId) : prices[0];
    switch (field) {
      case 'name':
        result = price.name;
        break;
      case 'value':
        result = price.value;
        break;
      case 'nameTranslated':
        result = price.nameTranslated;
        break;
    }
    return result;
  }

  /**
   * Returns discount pricing value or name for selected pricing id.
   * @param prices any
   * @param selectedId number
   * @param field string
   */
  public getDiscountPricing(discountPrices: any, selectedId: number, field: string): number | string {
    let result;

    const price = discountPrices.find(x => x.id === selectedId);

    if(!price) {
      return 0;
    }

    switch (field) {
      case 'name':
        result = price.name;
        break;
      case 'value':
        result = price.value;
        break;
      case 'nameTranslated':
        result = price.nameTranslated;
        break;
    }
    return result;
  }

  /**
   * Returns price for selected pricing period id.
   * @param product Product
   * @param pricingPeriodId number
   */
  public getPriceForPeriodId(product: Product, pricingPeriodId: number): number {
    const price = product.prices.find(x => x.id === pricingPeriodId);
    return parseFloat(String(price.value));
  }

  /**
   * Returns specific description string constructed from products description field
   * @param product Product
   * @param addMinor boolean
   */
  public getDescription(product: Product, addMinor: boolean = false): string {
    const desc = (typeof product.translated_product_description[this.localeId]) ?
      product.translated_product_description[this.localeId] : product.description;
    let descBasic = '';
    if (desc !== undefined && desc !== null) {
      const descKeys = Object.keys(desc);
      descKeys.forEach(x => {
        if ((desc[x].basic_info !== undefined || addMinor) && desc[x].title !== undefined) {
          descBasic += `${desc[x].title}: ${desc[x].text}, `;
        }
      });
    }
    return descBasic;
  }

  public getProductSets() {
    return this.productSets;
  }

  /**
   * This is used only for changing product grouping view on first cart page
   */
  public getProductSetsCartView() {
    return this.productSetsCartView;
  }

  public getProductById(productId: number, currency: string = null): Observable<Product> {
    return this.getProducts(null, [productId], null, currency)
      .pipe(map((res) => {
        return res[0];
      }));
  }

  /**
   * Retrieve configured products matching provided criteria
   * NOTE: This API method is designed to be used in the building of custom order forms.
   * As a result, only custom fields that have the ‘Show on Order Form’ setting enabled will be returned for a given product.
   * @param module Retrieve products utilising a specific module
   * @param pid Obtain a specific product id configuration. Can be a list of ids comma separated
   * @param gid Retrieve products in a specific group id
   * @param currency Currency key
   * @param showEmptyStock Boolean to show products with empty stock
   */
  public getProducts(module: string = null, pid: Array<number> = null, gid: number = null, currency: string = null, showEmptyStock = false):
    Observable<Product[]> {
    const filter: any = {};
    if (module !== null) {
      filter.module = module;
    }
    if (pid !== null) {
      filter.pid = pid;
    }
    if (gid !== null) {
      filter.gid = gid;
    }
    if (currency !== null) {
      filter.currency = currency;
    }

    if (typeof this.systemProductsSubscription === 'undefined' || module !== null || true || gid !== null || currency !== null) {
      if (module !== null || true || gid !== null || currency !== null) {
        this.systemProductsSubscription = this.getProductsSub(currency)
          .pipe(
            map((products) => {
                if (filter.currency !== undefined) {
                  delete filter.currency;
                }
                if (products) {
                  if (Object.keys(filter).length && products.length > 0) {
                    products = products.filter(item => {
                      for (const key in filter) {
                        if (filter.hasOwnProperty(key)) {
                          const isArrayFilter = Array.isArray(filter[key]);
                          if (item[key] === undefined || (isArrayFilter && filter[key].indexOf(item[key]) < 0)
                            || (!isArrayFilter && item[key] !== filter[key])) {
                            return false;
                          }
                          if (item.hidden) {
                            return false;
                          }
                          if (item.stockcontrol !== undefined && item.stockcontrol === 'true') {
                            if (item.stocklevel !== undefined && item.stocklevel <= 0 && !showEmptyStock) {
                              return false;
                            }
                          }
                        }
                      }
                      return true;
                    });
                  }
                }

                return products;
              }
            )
          );
      } else {
        this.systemProductsSubscription = this.getProductsSub(currency);
      }
    }
    return this.systemProductsSubscription;
  }

  private getProductsSub(currency: string = null): Observable<Product[]> {
    if (this.products !== undefined) {
      return of(this.products);
    }
    const data: any = {
      action: 'GetProducts',
      language: this.localeId,
      cache: true,
      parse: true
    };
    if (currency) {
      data.currency = currency;
    }
    return this.http.post<HttpResponse<Product[] | any>>(`${this.config.apiEndpoint}/public/request`, data, httpOptions)
      .pipe(
        map((res) => {
          let products: Product[] = [];
          if (res.body.products !== undefined) {
            products = res.body.products;

            // set product prices with configoptions first selection prices added
            products.forEach(prod => {
              const prices = _.cloneDeep(prod.prices);
              if (prod.configoptions.length > 0) {
                prod.configoptions.forEach(cfg => {
                  const cfgOptionPricing = (cfg.options[0].pricing[currency] !== undefined) ? cfg.options[0].pricing[currency] : null;
                  if (cfgOptionPricing) {
                    cfg.options[0].prices.forEach(cfgOptionPrice => {
                      cfgOptionPrice.value = isNaN(cfgOptionPricing[cfgOptionPrice.name]) ? cfgOptionPricing[cfgOptionPrice.name] :
                        ((cfgOptionPricing[cfgOptionPrice.name] === undefined
                          || cfgOptionPricing[cfgOptionPrice.name] === '') ?
                          null : parseFloat(cfgOptionPricing[cfgOptionPrice.name]));
                      const price = prices.find(x => x.id === cfgOptionPrice.id);
                      const quantity = (cfg.type_name === 'Quantity') ? cfg.minimum : 1;
                      if (price && quantity > 0) {
                        price.value = quantity * price.value + cfgOptionPrice.value;
                      }
                    });
                  }
                  if (cfg.options && cfg.options.length > 0 && currency) {
                    cfg.options.forEach(cfOptionP => {
                      const cfgOptOptKeys = Object.keys(cfOptionP.pricing[currency]);
                      if (cfgOptOptKeys.length > 0) {
                        cfgOptOptKeys.forEach(xt => {
                          cfOptionP.pricing[currency][xt] = (isNaN(cfOptionP.pricing[currency][xt])) ?
                            cfOptionP.pricing[currency][xt] : parseFloat(cfOptionP.pricing[currency][xt]);
                        })
                      }
                    });
                  }
                });
              }

              // add setup prices to dropdown prices
              prices.forEach(prc => {
                const basePriceKey = prc.name.charAt(0) + 'setupfee';
                if (prod.pricing[currency][basePriceKey] !== undefined) {
                  const setupPriceForPeriod = (isNaN(prod.pricing[currency][basePriceKey]) || prod.pricing[currency][basePriceKey] === '')
                    ? null : parseFloat(prod.pricing[currency][basePriceKey]);
                  if (setupPriceForPeriod > 0) {
                    prc.value += setupPriceForPeriod;
                  }
                }
              });
              prod.pricesWithDefaultConfigOption = prices;
            });

            this.products = products;
          }
          return products;
        }),
        take(1),
        publishLast(),
        refCount(),
        catchError(this.handleError('getProductsSub', []))
      );
  }

  public getProductGroups(): Observable<ProductGroup[]> {
    const data = {
      action: 'GetProductGroups',
      cache: true
    };
    if (typeof this.productGroupSubscription === 'undefined') {
      this.productGroupSubscription = this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/public/request`, data, httpOptions)
        .pipe(
          map((res) => {
            const productGroups = [];
            if (res.body.productgroups !== undefined && res.body.productgroups.length > 0) {
              res.body.productgroups.forEach(pg => {
                const productGroup = new ProductGroup();
                productGroup.id = pg.group_id;
                productGroup.name = pg.group_name;
                productGroup.tag = pg.group_tag;
                productGroup.intro = pg.group_intro;
                productGroup.hidden = (pg.hidden === 1);
                productGroup.order = parseInt(pg.display_order, 10);
                productGroup.created = pg.created;
                productGroup.updated = pg.updated;
                productGroup.linkedTags = pg.linked_tags;
                productGroup.translations = pg.translations;

                productGroups.push(productGroup);
              });
              this.productGroups = productGroups;
              return productGroups;
            }
            this.productGroupSubscription = productGroups;
          }),
          take(1),
          publishLast(),
          refCount(),
          catchError(this.handleError('getProductGroups', null))
        );
    }

    return this.productGroupSubscription;
  }

  public getGroupNameByTag(productTag: string): string | undefined {
    if (productTag === undefined) {
      return;
    }
    if (productTag === 'domain') {
      return $localize`Domene`;
    }
    const group = this.getGroupByTag(productTag);
    if (!group) {
      return null;
    }
    const name = (group.translations.translated_product_group_name[this.localeId] !== undefined)
      ? group.translations.translated_product_group_name[this.localeId] : group.name;
    const elem = document.createElement('textarea');
    elem.innerHTML = name;
    return elem.value;
  }

  public getGroupByGroupId(groupId: number): ProductGroup {
    return this.productGroups?.find(x => x.id === groupId);
  }

  public getGroupByTag(productTag: string): ProductGroup {
    const group = this.productGroups?.find(x => x.tag === productTag);
    if (!group) {
      return null;
    }
    return group;
  }

  getProductGroupsBySet(productSet: string): ProductGroup[] {
    let groupTags = this.productSets[productSet];
    if (groupTags === undefined) {
      for (const group in this.productSets) {
        if (this.productSets.hasOwnProperty(group)) {
          for (const subGroup of this.productSets[group]) {
            if (subGroup === productSet) {
              groupTags = this.productSets[group];
              break;
            }
          }
        }
      }
    }
    return this.productGroups.filter(group => groupTags.includes(group.tag));
  }

  getProductSetByTag(productTag: string): string {
    let productSet = null;
    for (const productSetsKey in this.productSets) {
      if (this.productSets.hasOwnProperty(productSetsKey) && this.productSets[productSetsKey].indexOf(productTag) >= 0) {
        productSet = productSetsKey;
        break;
      }
    }
    return productSet;
  }

  public clientHasProductGroup(productType: string[]) {
    if (this.clientHasPoductGroup === null || this.clientHasPoductGroup === undefined) {
      return false;
    }

    return this.clientHasPoductGroup.some(r => productType.indexOf(r) >= 0);
  }

  public getValueForOption(configOption, field: string, id: number = null) {
    const optionIds = configOption.options.map(x => x.id);
    let value;
    switch (field) {
      case 'min':
        value = Math.min(...optionIds);
        break;
      case 'minSlider':
        const m = Math.min(...optionIds);
        const minV = configOption.options.find(x => x.id === m);
        value = minV.name;
        break;
      case 'max':
        value = Math.max(...optionIds);
        break;
      case 'maxSlider':
        const v = Math.max(...optionIds);
        const maxV = configOption.options.find(x => x.id === v);
        value = maxV.name;
        break;
      case 'name':
        value = (id) ? configOption.options.find(x => x.id === id).name : configOption.options.map(x => x.name);
        break;
    }

    return value;
  }

  public getOptionsForCFG(cfgOption) {
    const ticks: any[] = [];
    if (cfgOption.options.length >= cfgOption.maximum) {
      cfgOption.options.forEach(opt => {
        ticks.push({
          value: opt.id,
          legend: opt.name,
        });
      });

      const options: Options = {
        showTicks: true,
        showTicksValues: true,
        showSelectionBar: true,
        stepsArray: ticks
      };

      return options;
    } else {
      const ticksArray = [];
      const stepFrequency = cfgOption.maximum > 20 ? Math.abs((cfgOption.maximum / 4)) : 1;
      for (let index = cfgOption.minimum; index <= cfgOption.maximum; ++index) {
        if ((index % stepFrequency) === 0 || index === cfgOption.minimum || index === cfgOption.maximum) {
          ticksArray.push(index);
        }
      }
      const options: Options = {
        floor: cfgOption.minimum,
        ceil: cfgOption.maximum,
        step: cfgOption.minimum,
        showSelectionBar: true,
        ticksArray
      };

      return options;
    }
  }

  /**
   * get list of client products
   */
  public getClientProducts(data = null, options = httpOptions): Observable<Product[]> {
    if (data === null) {
      data = {
        action: 'GetClientsProducts',
        language: this.localeId
      };
    } else {
      data.action = 'GetClientsProducts';
      data.language = this.localeId
    }
    const limitstart = (typeof data.limitstart !== 'undefined') ? data.limitstart : null;
    const limitnum = (typeof data.limitnum !== 'undefined') ? data.limitnum : null;
    const serviceName = (typeof data.name !== 'undefined') ? data.name : null;
    const serviceid = (typeof data.serviceid !== 'undefined') ? data.serviceid : null;
    const saveDbCache = (typeof data.saveCache !== 'undefined') ? data.saveCache : null;
    const dataChanged = (this.productSubscriptionData.limitstart !== limitstart ||
      this.productSubscriptionData.limitnum !== limitnum || this.productSubscriptionData.serviceid !== serviceid);

    let products;
    if (limitnum === 200 && limitstart === 0) {
      if (this.productsSubscription === undefined || saveDbCache) {
        products = this.sendGetClientProducts(data, options, true);
        this.productsSubscription = products;
      }
    } else if (this.productSubscription === undefined || dataChanged || saveDbCache) {
      products = this.sendGetClientProducts(data, options);
      this.productSubscription = products;
      this.productSubscriptionData.limitstart = limitstart;
      this.productSubscriptionData.limitnum = limitnum;
      this.productSubscriptionData.serviceid = serviceid;
      this.productSubscriptionData.serviceName = serviceName;
    }

    if (products === undefined) {
      products = (limitnum === 200 && limitstart === 0) ? this.productsSubscription : this.productSubscription;
    }

    return products;
  }

  private sendGetClientProducts(data = null, options = httpOptions, updateGroup: boolean = false): Observable<Product[]> {
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, options)
      .pipe(
        map((res) => {
          if (updateGroup && res.body.hasProductGroups !== null && res.body.hasProductGroups.length > 0) {
            this.clientHasPoductGroup = res.body.hasProductGroups;
          }
          if (res.body.products !== undefined && res.body.products.length > 0) {
            const products: Product[] = res.body.products;
            products.forEach(x => {
              x.gid = x.groupid;
            });
            return products;
          }
        }),
        take(1),
        publishLast(),
        refCount(),
        catchError(this.handleError('getClientProducts', null))
      );
  }

  /**
   * Returns product by id
   * @param serviceid product id
   */
  public getClientProductById(serviceid: number): Observable<Product> {
    const data = {
      action: 'GetClientsProducts',
      cache: true,
      language: this.localeId,
      serviceid
    };

    return this.http.post<HttpResponse<Product | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (res.body.products !== null && res.body.products.length > 0) {
            return res.body.products[0];
          }
        }),
        catchError(this.handleError('getClientProductById', null))
      );
  }

  /**
   * Updates product.
   * TODO: Add more fields to 'allowedUpdateFields' list if required
   * @param serviceId number
   * @param updateFields key->value
   */
  public updateProduct(serviceId: number, updateFields: {}): Observable<{ success: boolean, message: string }> {
    const allowedUpdateFields = [
      'paymentmethod',
      'servicepassword',
      'domain'
    ];
    const data = {
      action: 'UpdateClientProduct',
      serviceid: serviceId
    };
    for (const key in updateFields) {
      if (allowedUpdateFields.indexOf(key) <= -1) {
        return of({
          success: false,
          message: `Update of field ${key} is not allowed in this method.`
        });
      } else {
        data[key] = updateFields[key];
      }
    }

    return this.http.post<HttpResponse<{ result: string, serviceid: number } | any>>
    (`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (res.body.result !== undefined && res.body.result === 'success') {
            return {
              success: true,
              message: $localize`Polja su uspješno osvježena`
            };
          }
          return {
            success: false,
            message: $localize`Osvježavanje polja neuspješno`
          };
        }),
        catchError(this.handleError('updateProduct', null))
      );
  }

  /**
   * Updates password on services
   * @param serviceid number
   * @param servicepassword string
   */
   public changeServicePassword(serviceid: number, servicepassword: string): Observable<{ success: boolean, message: string }> {
    const data = {
      action: 'ModuleChangePw',
      serviceid,
      servicepassword
    };

    return this.http.post<HttpResponse<{ result: string, serviceid: number } | any>>
    (`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (res.body.result !== undefined && res.body.result === 'success') {
            return {
              success: true,
              message: $localize`Lozinka je uspješno promijenjena`
            };
          }
          return {
            success: false,
            message: $localize`Promjena lozinke nije bila uspješna`
          };
        }),
        catchError(this.handleError('updateProduct', null))
      );
  }


  /**
   * Returns one time login link for user and it's domain.
   * @param product The cPanel, WHM, or Webmail account's main domain.
   * @param service The account's service type.
   * @param goto Location to which the script will redirect the user. The get_loggedin_url() subroutine uses this value to create the URL.
   */
  public getLogMeInLink(product: Product, service: string, goto: string = null):
    Observable<{ success: boolean, result: string, message: string }> {
    const data = {
      user: product.username,
      productid: product.pid,
      serviceid: product.id,
      hostname: product.serverhostname,
      service,
      goto
    };
    return this.http.post<HttpResponse<{ success: boolean, result: string, message: string } | any>>
    (`${this.config.apiEndpoint}/user/cpanel-logmein`, data, httpOptions)
      .pipe(
        map((resp) => {
          return resp.body;
        }),
        catchError(this.handleError('getLogMeInLink', []))
      );
  }

  /**
   * Cancels product at a given period with given reason.
   * @param productId number - product/service id
   * @param type string
   * @param reason string
   */
  public cancelProductService(productId: number, type: string, reason: string): Observable<boolean> {
    const data = {
      action: 'AddCancelRequest',
      serviceid: productId,
      cache: false,
      invalidateKeys: ['GetClientsProducts'],
      language: this.localeId,
      type,
      reason
    };

    return this.http.post<HttpResponse<boolean | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((resp) => {
          return resp.body.result && resp.body.result === 'success';
        }),
        catchError(this.handleError('cancelProductService', false))
      );
  }

  /**
   * Method for getting upgrade info and executing update.
   * @param serviceId number
   */
  public getClientProductUpgrade(serviceId: number):
    Observable<{
      result: string,
      step: number,
      upgrades: { package: ProductUpgradePackage[] }[]
    }> {
    const data = {
      action: 'ClientProductUpgrade',
      serviceid: serviceId,
      language: this.localeId,
      step: 1
    };
    return this.http.post<HttpResponse<{
      result: string,
      step: number,
      upgrades: { package: ProductUpgradePackage[] }[]
    } | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((resp) => {
          return resp.body;
        }),
        catchError(this.handleError('getClientProductUpgrade', null))
      );
  }

  /**
   * Send upgrade request
   * @param serviceid number - product id
   * @param upgradepid number - id of upgrade package
   * @param billingcycle string - chosen billing cycle (monthly, annually, semiannually...)
   * @param paymentmethod string - payment method
   * @param type string - 'product'
   */
  public upgradeClientProductUpgrade(serviceid: number, upgradepid: number, billingcycle: string, paymentmethod: string, type: string):
    Observable<ProductUpgradeResponse> {
    const data = {
      action: 'ClientProductUpgrade',
      serviceid,
      step: 2,
      paymentmethod,
      upgradepid,
      billingcycle,
      type,
      checkout: true,
      language: this.localeId
    };
    return this.http.post<HttpResponse<ProductUpgradeResponse | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((resp) => {
          return resp.body;
        }),
        catchError(this.handleError('upgradeClientProductUpgrade', null))
      );
  }

  /**
   * Sets group list from outside (used when products are loaded on startup requests)
   * @param productGroups any
   */
  public setClientHasProductGroups(productGroups: any) {
    this.clientHasPoductGroup = productGroups;
  }

  /**
   * Retrieves the product usage statistics.
   * @param productId - The product ID.
   * @returns An Observable that emits the product usage statistics.
   */
  public getProductUsageData<T>(productId: number): Observable<T> {
    const data = {
      action: "ModuleCustom",
      serviceid: productId,
      func_name: "GetUsageData",
    };
    return this.http
      .post<HttpResponse<T>>(
        `${this.config.apiEndpoint}/user/request`,
        data,
        httpOptions
      )
      .pipe(
        map((resp) => {
          return resp.body;
        }),
        catchError(this.handleError("getProductUsageData", null))
      );
  }
}
