import {Component, Inject, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {of, Subscription} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';

import {Client} from '../_models';
import {CartService} from './cart.service';
import {ProductService} from '../_services/product.service';
import {AlertService} from '../_services';
import {ProductGroup} from '../_models/product-group';
import {Product} from '../_models/product';
import {Currency} from '../_models/currency';
import {CartOrderItem} from './cart-order-item';
import {ProductDomainItem} from './product-domain-item';
import { APP_CONFIG, AppConfig } from '../app-config.module';

@Component({
  templateUrl: './cart.component.html',
  selector: 'app-cart'
})
export class CartComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  user: Client;
  cartCurrency: Currency;
  cartItems: CartOrderItem[] = [];
  domainItems: ProductDomainItem[] = [];
  products: Product[];
  productSets;
  productSetsCartView;
  productId: number;
  cartId: number;
  step = 0;
  groupId: number;
  groupSet: string;
  productGroups: ProductGroup[];
  hasChild = false;
  loading = {
    groups: false,
    products: false
  };
  public displayHrk = false;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private alertService: AlertService,
    protected route: ActivatedRoute,
    private router: Router,
    private cartService: CartService,
    private productService: ProductService
  ) {
    this.displayHrk = this.config.displayHrk;

    const currSub = this.cartService.getCartCurrency()
      .subscribe(currency => {
        this.cartCurrency = currency;
        this.loadProducts();
      });
    this.subscription.add(currSub);

    const cartSub = this.cartService.orderItems$
      .subscribe(cartItems => {
        this.cartItems = cartItems;
      });
    this.subscription.add(cartSub);
    const cartDomainSub = this.cartService.domainItems$
      .subscribe(domainItems => {
        this.domainItems = domainItems;
      });
    this.subscription.add(cartDomainSub);

    const navigationSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      let cartItem = null;
      if (event.url.indexOf('/cart/options') >= 0) {
        const idIndex = event.url.lastIndexOf('/');
        const cartItemId = parseInt(event.url.substring(idIndex + 1), 10);
        cartItem = this.cartService.getCartItemByCartId(cartItemId);
      }
      this.setUp(cartItem);
    });
    this.subscription.add(navigationSub);
  }

  setUp(cartItem: CartOrderItem = null) {
    this.cartId = (cartItem) ? cartItem.cartId : null;
    this.productSets = this.productService.getProductSets();
    this.productSetsCartView = this.productService.getProductSetsCartView();
    this.loading.groups = true;
    const groupSub = this.productService.getProductGroups()
      .pipe(take(1))
      .subscribe(groups => {
        this.loading.groups = false;
        this.productGroups = groups;
      });
    this.subscription.add(groupSub);

    const paramSub = this.route.queryParams.pipe(
      switchMap(params => {
        return of(params);
      })
    ).subscribe(params => {
      this.groupId = (cartItem) ? cartItem.gid : ((typeof params.gid !== 'undefined') ? parseInt(params.gid, 10) : null);
      this.groupSet = (typeof params.groupSet !== 'undefined') ? params.groupSet : null;
      this.productId = (cartItem) ? cartItem.pid : ((typeof params.pid !== 'undefined') ? parseInt(params.pid, 10) : null);
      const childRoute = (this.route.snapshot.firstChild !== null) ? this.route.snapshot.firstChild.url[0].path : null;
      this.validateRouteAndRedirect(childRoute, params, this.groupId, this.productId, this.groupSet);
    });
    this.subscription.add(paramSub);
  }

  loadProducts() {
    if (!this.cartCurrency) {
      return false;
    }
    this.loading.products = true;
    const productsSub = this.productService.getProducts(null, null, null, this.cartCurrency?.code)
      .subscribe(products => {
        this.loading.products = false;
        this.products = products;
      });
    this.subscription.add(productsSub);
  }

  validateRouteAndRedirect(route: string, params, groupId: number = null, productId: number = null, groupSet: string = null) {
    if (route) {
      this.step = (typeof this.route.snapshot.firstChild.data.step !== 'undefined') ? this.route.snapshot.firstChild.data.step : 0;
      this.hasChild = true;
    } else {
      this.hasChild = false;
      if (groupId || groupSet) {
        this.hasChild = true;
        return this.router.navigate([`/cart/product`], {queryParams: params});
      }
    }
  }

  getGroupNameByTag(productTag: string): string {
    return this.productService.getGroupNameByTag(productTag)?.toUpperCase();
  }

  getProductGroupQueryParams(productTag: string) {
    if (productTag === 'domain') {
      return {
        groupSet: 'domain'
      };
    }
    const group = this.productService.getGroupByTag(productTag);
    if (group) {
      return {
        gid: group.id
      };
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
