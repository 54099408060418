<div class="bd-bottom p-3 bg-white">
  <h3 class="font-weight-bold text-color-2" i18n>Promjena korisničke lozinke</h3>
</div>

<div class="p-3">
  <div class="container-fluid">
    <div class="row">
    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" class="col-12 col-lg-8 col-xl-6 offset-lg-2 offset-lg-3">
      <div class="form-group">
        <label for="currentPassword" i18n>Trenutna lozinka</label>
        <div class="input-group">
          <input id="currentPassword" formControlName="currentPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.currentPassword.errors }" [type]="!shown.currentPassword ? 'password' : 'text'" />
          <div class="input-group-append toggable">
            <div class="input-group-text" (click)="viewInput('currentPassword')">
              <fa-icon tooltip="Vidi lozinku" i18n-tooltip="Vidi lozinku" [icon]="faEyeSlash" *ngIf="!shown.currentPassword"></fa-icon>
              <fa-icon tooltip="Sakrij lozinku" i18n-tooltip="Sakrij lozinku" [icon]="faEye" *ngIf="shown.currentPassword"></fa-icon>
            </div>
          </div>
          <div *ngIf="submitted && f.currentPassword.errors" class="invalid-feedback w-100">
            <div *ngIf="f.currentPassword.errors.required" i18n>Trenutna lozinka je obavezna</div>
          </div>
        </div>
      </div>
        <div class="form-group">
          <label for="password" i18n>Nova lozinka</label>
          <div class="input-group">
            <input id="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }" [type]="!shown.password ? 'password' : 'text'" />
            <div class="input-group-append toggable">
              <div class="input-group-text" (click)="viewInput('password')">
                <fa-icon tooltip="Vidi lozinku" i18n-tooltip="Vidi lozinku" [icon]="faEyeSlash" *ngIf="!shown.password"></fa-icon>
                <fa-icon tooltip="Sakrij lozinku" i18n-tooltip="Sakrij lozinku" [icon]="faEye" *ngIf="shown.password"></fa-icon>
              </div>
            </div>
            <div *ngIf="f.password.touched && f.password.errors" class="invalid-feedback w-100">
              <div *ngIf="f.password.errors.required" i18n>Lozinka obavezna</div>
              <div *ngIf="f.password.errors.minlength" i18n>Lozinka mora sadržavati najmanje 8 znakova</div>
              <div *ngIf="f.password.errors.maxlength" i18n>Lozinka mora sadržavati najviše 64 znaka</div>
              <div *ngIf="f.password.errors.pattern">Lozinka mora sadržavati najmanje jedno veliko slovo, jedno malo slovo, jedan broj i jedan specijalni znak</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPassword" i18n>Potvrdi novu lozinku</label>
          <div class="input-group">
            <input id="confirmPassword" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': f.confirmPassword.touched && f.confirmPassword.errors }" [type]="!shown.confirmPassword ? 'password' : 'text'" />
            <div class="input-group-append toggable">
              <div class="input-group-text" (click)="viewInput('confirmPassword')">
                <fa-icon tooltip="Vidi lozinku" i18n-tooltip="Vidi lozinku" [icon]="faEyeSlash" *ngIf="!shown.confirmPassword"></fa-icon>
                <fa-icon tooltip="Sakrij lozinku" i18n-tooltip="Sakrij lozinku" [icon]="faEye" *ngIf="shown.confirmPassword"></fa-icon>
              </div>
            </div>
            <div *ngIf="f.confirmPassword.touched && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required" i18n>Potvrda nove lozinke je obavezna</div>
              <div *ngIf="f.confirmPassword.errors.mustMatch" i18n>Lozinke moraju biti jednake</div>
            </div>
          </div>

          <password-strength-meter [password]="f.password.value"></password-strength-meter>
          <p class="mb-3" i18n>Kvaliteta lozinke</p>

          <button class="btn rounded-light btn-outline-info mb-3" type="button" (click)="generateRootPassword()" i18n>Kreiraj novu</button>

          <div class="font-italic mb-3">
            <p class="mb-3" i18n>Jačina lozinke</p>
            <p i18n>Lozinka mora sadržavati 8-64 znaka</p>
            <p><strong i18n>Savjeti za sigurnu lozinku:</strong></p>
            <p i18n>Koristi barem jedno veliko i jedno malo slovo</p>
            <p i18n>Uključi barem jedan od ponuđenih znakova {{ '($ % @ ? + - ` ! ; , . : # * ( ) _ [ ] { })' }}</p>
            <p i18n>Izbjegavaj riječi iz rječnika</p>
          </div>
        </div>
        <hr>
        <div class="d-flex justify-content-between">
          <a routerLink="/profile" class="btn btn-primary rounded-light" *ngIf="!loading" i18n>Odustani</a>
          <button class="btn rounded-light btn-green" *ngIf="!loading" i18n>Promijeni</button>
          <app-loader *ngIf="loading"></app-loader>
        </div>
      </form>
    </div>
  </div>
</div>
